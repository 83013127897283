import React from "react";

import Particle from "reusecore/Particle";
import PageHeaderWrapper from "./pageHeader.style";

const PageHeader = ({ categories, title, author, subtitle }) => {
  return (
    <PageHeaderWrapper>
      <Particle />
      <div className="page-header">
        <h1>{title}</h1>
        <h4>{subtitle}</h4>
          <div className="breadcrumbs post">
            {author && (
              <>
                <h4>By:</h4> <span>{author.name}</span>
              </>
            )}
          </div>
      </div>
    </PageHeaderWrapper>
  );
};
export default PageHeader;
