import { createGlobalStyle } from 'styled-components'; 
import 'docsearch.js/dist/cdn/docsearch.css';

 

const GlobalStyle = createGlobalStyle`

::selection {
    background: #333333;
    color: #ffffff;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  *:focus {
    outline: none;
  }

html{
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
}

body,html {
    

}
body {
  line-height: 28px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

p{
    margin: 0 0 15px 0;
}

a{
    text-decoration: none;
  }
  img{
    max-width: 100%;
  }


section{
    position: relative;
}
`


export { GlobalStyle };
