import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { Container, Row, Col } from "reusecore/Layout";
import PageHeader from "reusecore/PageHeader";
import { LoginPageWrapper } from "./loginGrid.style";
import { Avatar, Card } from "antd";
import ExternalLinkIcon from "../shared/Navigation/ExternalLinkIcon";

const LoginLanding = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            products {
              name
              description
              loginLink
              logo
            }
          }
        }
      }
    `,
  );
  const products = site.siteMetadata.products;

  return (
    <LoginPageWrapper>
      <PageHeader title="Login" subtitle="Welcome to Sortd. Choose your product below to continue:"/>

      <div className="blog-page-wrapper">
        
        <Container>
          <Row>
            <Col xs={12} lg={12}>
              <div className="blog-grid-wrapper">
                <Row>
                  {products.map(product => (
                    <Col xs={12} sm={6}>
                      <a href={product.loginLink} target='_blank' rel="noopener noreferrer">
                          <Card 
                            hoverable
                            style={{marginBottom: 12}}
                          >
                            <Card.Meta
                                    avatar={<Avatar src={product.logo}/>}
                                    title={<span>{product.name} <ExternalLinkIcon /></span>}
                                    description={product.description}
                            />
                        </Card>
                        </a>
                        </Col>
                  ))}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </LoginPageWrapper>
  );
};

export default LoginLanding;
